/* Container styling */
.sst-classes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #ffffff; /* Light golden background to match theme */
    min-height: 100vh; /* Full screen height */
    background-image: url('../../../../public/bg7.jpg'); 
    background-size:auto;
    /* background-position: center; */
    /* background-repeat: repeat; */
    background-attachment: fixed;
  }
  
  /* Heading */
  .sst-classes-heading {
    font-size: 2.5rem;
    font-weight: bold;
    color: #004aad; /* Blue color matching the header/nav bar */
    text-align: center;
    /* text-transform: uppercase; */
    border-bottom: 4px solid #ffcc00; /* Yellow underline */
    padding-bottom: 10px;
    margin-bottom: 30px;
    font-family: 'Arial', sans-serif;
    letter-spacing: 2px;
  }
  
  /* Link styling */
  .sst-class-link {
    margin: 10px 0;
    padding: 12px 20px;
    background-color: white;
    border: 2px solid #004aad; /* Blue border */
    border-radius: 8px;
    cursor: pointer;
    width: 220px;
    text-align: center;
    color: #004aad; /* Blue text */
    font-weight: bold;
    /* text-transform: capitalize; */
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  /* Hover effect for links */
  .sst-class-link:hover {
    background-color: #004aad; /* Blue background on hover */
    color: white;
    transform: translateY(-5px); /* Lift effect */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .sst-classes-container{
      width: 100%;
    }
    .sst-classes-heading {
      font-size: 1.5rem;
    }
  
    .sst-class-link {
      width: 180px;
      font-size: 0.9rem;
      padding: 10px 15px;
    }
  }
  