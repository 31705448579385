
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}

.container {
  /* width: 100%; */
  max-width: 100%; /* Adjust the maximum width as needed */
  margin: 20px;
  padding-left: 15px;
  font-family: "Inter", sans-serif;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

h1 {
  font-weight: 900;
  font-size: 70px;
  /* color: #ffb703; */
  /* color: #dbb42c; */
  /* color: #c9a227; */
  /* color: #c9a227; */
  /* color: #c9a227; */
  color: #c9a227;
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 0.5px;
}
p {
  color: black;
  font-weight: 600;
}

.text-column {
  text-align: center;
  padding: 40px;
  margin: 50px;
  font-family: "Playfair Display", serif;
}

/* Define the gradient colors */
.horizontal_line {
  height: 8px; /* Adjust this to change the thickness of the line */
  width: 440px;
  background: linear-gradient(
    to right,
    #3a86ff,
    #ffbe0b
  ); /* Change colors as needed */
  border-radius: 30px;
  border: none;
}

.section_one_image {
  /* border: 2px solid red; */
  width: 400px;
  height: 400px;
  /* max-width: 100%; */
}



/* For phones */
@media only screen and (max-width: 768px) {
  .container {
    overflow: hidden;
    margin: 20px;
    margin-top: 5px;
    padding: 20px;
  }

  .row {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .text-column {
    text-align: center;
  }

  .image-column {
    display: flex;
    justify-content: center;
  }

  .section_one_image {
    width: 100%; /* Adjust width as needed */
    max-width: 100%; /* Ensure image does not exceed container width */
    height: auto; /* Maintain aspect ratio */
  }

  .horizontal_line {
    width: 90%; /* Adjust width as needed */
  }
  h1 {
    font-size: xx-large;
    color: #c9a227;
  }
}

/* For tablets */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  /* Your styles for tablets here */
  h1 {
    font-size: 40px;
    color: #c9a227;
  }
}