.homeworks-container {
  max-width: 100%;
  /* padding: 20px; */
  background-color: #fef1be;
  background-image: url('../../../../public/bg7.jpg'); 
  background-size:auto;
  background-position: center;
  background-repeat: repeat;
  background-attachment: fixed;
  /* height:90vh;
  position: fixed; */
  min-height: 90vh; /* Allows it to expand if content requires more space */
  display: flex; /* Flexbox for layout */
  flex-direction: column;
  overflow: hidden; 
}

/* Heading styles */
.homeworks-heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #004aad; /* Blue to match theme */
  text-align: center; /* Center the heading */
  border-bottom: 4px solid #ffcc00; /* Yellow underline */
  padding-bottom: 10px;
  letter-spacing: 2px;
  font-family: 'Arial', sans-serif;
}

/* Table styles */
.homeworks-table {
  width: 100%; /* Ensure the table takes full width */
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* overflow: hidden;
  margin-top: 20px; */
  overflow-y: auto;
  max-height: 100%;
}

/* Table header */
.homeworks-table thead th {
  background-color: #004aad; /* Dark blue for table header matching the navbar */
  color: white;
  padding: 15px;
  text-align: left;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Students table container to make it scrollable on smaller screens */
.heading-students-table-container {
  max-width: 100%;
  overflow-x: auto;  
  margin-top: 10px;
  max-height: calc(90% - 70px);
  overflow-y: auto; 
  /* padding: 15px; */
  flex-grow: 1;
}

/* Table rows */
.homeworks-table tbody tr {
  transition: background-color 0.3s ease;
}

.homeworks-table tbody tr:hover {
  background-color: #e2e8f0; /* A soft hover effect */
}

/* Table cells */
.homeworks-table tbody td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
  white-space: nowrap; /* Prevent text wrapping for better readability */
  max-width: 300px; /* Set the desired max width */
  overflow-wrap: break-word; /* Allow text to break at word boundaries */
  word-wrap: break-word; /* For compatibility with older browsers */
  white-space: normal;
}

/* Table link */
.homeworks-table a {
  color: #004aad; /* Blue link color matching the navbar */
  text-decoration: none;
  font-weight: bold;
}

.homeworks-table a:hover {
  text-decoration: underline;
}

/* Button styles */
.action-button {
  background-color: #ffcc00; /* yellow for action buttons matching the navbar */
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #cca713; /* Slightly darker yellow on hover */
}

.action-button.disabled {
  background-color: gray;
  cursor: not-allowed;
}


/* Status-specific row colors */
.graded-row {
  background-color: #98e2a3; /* Lighter green for graded status */
}

.pending-row {
  background-color: #ff9999; /* Lighter red for pending status */
}

/* Responsive Design for smaller screens */
@media (max-width: 768px) {

  .homeworks-container{
    width: 100%;
  }
  .homeworks-heading {
    font-size: 2rem;
  }

  .homeworks-table tbody td {
    padding: 10px;
    font-size: 0.9rem;
  }

  .action-button {
    padding: 8px 15px;
    font-size: 0.9rem;
  }
}
