.questionForm {
    margin: 30px;
}

.questionForm label {
    font-size: 1.2em;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.questionForm input[type="text"], .questionForm textarea, .questionForm input[type="file"] {
    width: 100%;
    padding: 10px;
    font-size: 1.2rem;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.questionForm select {
    width: 100%;
    padding: 10px;
    font-size: 1.2em;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.questionForm button[type="submit"], .questionForm button[type="button"] {
    background-color: #4CAF50;
    color: black;
    padding: 10px 20px;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* margin-top: 10px;
    margin-left: 10px; */
}

.questionForm button[type="submit"]:hover, .questionForm button[type="button"]:hover {
    background-color: #45a049;
}

.questionForm .option-input, .questionForm .image-input, .questionForm .hint-input, .questionForm .ref-input {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.questionForm .option-input input, .questionForm .image-input input, .questionForm .hint-input input, .questionForm .ref-input input {
    flex: 1;
    margin-right: 10px;
}

.questionForm .option-input button, .questionForm .image-input button, .questionForm .hint-input button, .questionForm .ref-input button {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1.2em;
    margin-top: -10px;
}

.questionForm .option-input button:hover, .questionForm .image-input button:hover, .questionForm .hint-input button:hover, .questionForm .ref-input button:hover {
    background-color: #d32f2f;
}

.questionForm .image-input input[type="file"] {
    padding: 5px;
    border: none;
}

.questionForm .image-input button {
    margin-left: 10px;
}

.questionForm .file-input input[type="file"] {
    padding: 5px;
    border: none;
}

.questionForm .file-input button {
    margin-left: 10px;
}

.questionForm img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.question-selector{
    height: 13rem;
    overflow-y: auto;
}

.question-selector-grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr); /* Creates a 5-column grid */
    gap: 8px; /* Equivalent to Tailwind's gap-2 */
    padding: 16px; /* Equivalent to Tailwind's p-4 */
}

.question-selector-grid button {
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: gray;
    color: white;
}

.question-selector-grid button.bg-blue-500 {
    background-color: #4299e1; 
}

.question-selector-grid button.bg-red-500 {
    background-color: #c1bcbc;
}

.question-selector-grid  button.bg-green-500 {
    background-color: #3d8804; 
    color: whitesmoke;
}
