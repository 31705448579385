/* Wrapper for the entire grade page */
.marker-grade-wrapper {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    max-width: 100vw;
    margin: 0 auto;
    min-height: 90;
    padding:20px;
    background-image: url('../../../../../public/bg7.jpg'); 
    background-size:auto;
    background-position: center;
    background-repeat: repeat;
    background-attachment: fixed;
}

.marker-grade-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.marker-grade-info-left {
    width: 100%;

}

.marker-grade-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #004aad; /* Blue to match header/nav bar */
    text-align: center; /* Center heading */
    border-bottom: 4px solid #ffcc00; /* Yellow underline */
    padding-bottom: 10px;
    margin-left: 20px;
    font-family: 'Arial', sans-serif;
}

/* .marker-grade-info-right {
    width: 45%;
} */

.marker-grade-heading {
    /* font-size: 2rem; */
    font-weight: bold;
    margin-bottom: 20px;
    color: #004aad; /* Blue to match header/nav bar */
    text-align: center; /* Center heading */
    border-bottom: 4px solid #ffcc00; /* Yellow underline */
    padding-bottom: 10px;
    margin-left: 20px;
}

.marker-grade-form-container {
    margin-top: 20px;
}

/* Form group for each input/label pair */
.marker-grade-form-group {
    margin-bottom: 20px;
}

/* Labels for the form inputs */
.marker-grade-label {
    display: block;
    margin-bottom: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;
    color: #004aad;
}

/* Input and textarea fields */
.marker-grade-input,
.marker-grade-textarea,
.marker-grade-select {
    width: 95%;
    padding: 12px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    background-color: #fff; /* White background */
}

/* Textarea specific styling */
.marker-grade-textarea {
    resize: vertical;
    min-height: 100px;
}

/* Submit button */
.marker-grade-submit-button {
    padding: 12px 20px;
    background-color: #ffcc00; /* Blue background */
    color: black;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

/* Hover effect for submit button */
.marker-grade-submit-button:hover {
    background-color: #cca713; /* Darker blue on hover */
}

/* Error message styling */
.marker-grade-error-message {
    color: red;
    margin-top: 10px;
    font-weight: bold;
    font-size: 1rem;
}

.marker-show-files {
    display: flex;
    width: 100%;
    height: 100%;
}

.marker-pdfviewer,
.marker-iframe {
    margin-left: 20px;
}

.full-width {
    flex: 1;
    width: 100%;
    margin-right: 0;
}

.half-width {
    flex: 1;
    width: 50%; 
}

.marker-iframe {
    flex: 1;
    width: 50%;
}

.marker-copy-show-button {
    font-size: medium;
    color: #004aad;
    cursor: pointer;
}

.marker-copy-show-button:hover {
    text-decoration: underline;
}


/* Responsive Design */
@media (max-width: 768px) {
    .marker-grade-label {
        font-size: 1rem;
    }

    .marker-grade-input,
    .marker-grade-textarea,
    .marker-grade-select {
        padding: 10px;
        font-size: 0.9rem;
    }

    .marker-grade-submit-button {
        padding: 10px 15px;
        font-size: 0.9rem;
    }
}
