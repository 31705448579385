.pdf-viewer-wrapper {
  display: flex;
  flex-direction: column;
  height: 75vh;
  width: 100%;
  position: relative;
}

.pdf-viewer-wrapper .pdf-viewer-content {
  flex: 1;
  width:100%;
  border: 2px solid #007bff;
  background-color: #ffffff;
}

.pdf-viewer-wrapper.loading .pdf-viewer-content {
  pointer-events: none;
  opacity: 0.5; 
}

.pdf-viewer-save-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self:center;
  margin-top: 10px;
}

.pdf-viewer-save-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.pdf-viewer-loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}
