.quiz-set-container {
  display: flex;
  height: 100vh;
}

.filter-container {
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.filter-container > select,
.filter-container > input {
  width: 220px;
  margin: 5px;
  border-radius: 30px;
  height: 25px;
}

.sidebar {
  /* background-color: antiquewhite; */
  overflow: auto;
  position: fixed;
  top: 0;
  width: 250px;
  padding: 10px;
}

.drag-handle {
  height: 10px;
  cursor: ew-resize;
}

.main-content {
align-items: center;
  width: 100%; 
  padding: 10px; 
  /* height: 85vh; */
  overflow-x: hidden;
  
}

.quiz-solution-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}


.questions-content
{
/* Add padding and dimensions to viewer */
height: calc(100% - 20px);
width: 100%;
padding: 10px;
}

.tab-navigation {
  margin: 5px;
  display: flex;
  /* flex-wrap: wrap; */
  /* overflow-x: hidden; */
  /* overflow: auto; */
  margin-bottom: 10px;
}

.report-bug-button {
  padding: 5px;
  margin: auto;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  background-color: #333;
  color: #fff;
  cursor: pointer;
}

.questions-quiz-content {
  width: 40%; 
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f9f9f9; 
  height: 100vh; 
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.questions-quiz-content-no-sol {
  width: 100%; 
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f9f9f9; 
  height: 100vh; 
  place-items: center;
}


.solution-sidebar {
  width: 60%; 
  overflow-y: auto; 
  padding: 20px;
  box-sizing: border-box; 
  background-color: #fff;
  height: 100vh;
}

.question-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.options {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.option {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  margin: 5px;
}

.option.selected {
  background-color: #f0f0f0;
}

.option.correct {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.option.incorrect {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

.download-selection-button {
  background-color: #2ad300;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 10px;
  align-self: center;
}

.download-selection-button:hover {
  background-color: #1d9200;
}



.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quiz-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90vh;
  overflow-y: auto;
}

.question-block {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  margin:auto;
  margin-bottom: 20px;
  width:82%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.h3-head {
  margin-bottom: 10px;
}

.question-block img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.options {
  display: flex;
  justify-content: space-between;
}

.option {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  flex: 1;
  text-align: center;
  margin: 5px;
  background-color: #f0f0f0;
}

.option.selected {
  border-color: #007bff;
  background-color: #ffbe0b; 
  color: #000; 
}



.option.selected.correct {
  background-color: #00ff00; /* Green */
}

.option.selected.incorrect {
  background-color: #ff0000; /* Red */
}

/* Submit button styling */
.submit  button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  align-self: center;
}



/* Add this CSS to your existing stylesheet or create a new one */
.button {
background-color: #808080; /* Grey color */
color: #000; /* Black text color */
padding: 10px 20px;
margin: 5px;
border: none;
border-radius: 5px;
cursor: pointer;
outline: none;
}

.mustard {
background-color: #FFDB58; /* Mustard color */
}

.correct {
background-color: #32CD32; /* Green color */
color: #fff; /* White text color */
}

.wrong {
background-color: #FF6347; /* Red color */
color: #fff; /* White text color */
}

.quiz-content{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

/* quiz_set.css */

/* Add this style for the submit button */
button.submit-button {
/* background-color: indigo;  */
background-color: #007bff;
border: none;
color: white;
padding: 15px 32px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
margin: 4px 2px;
cursor: pointer;
border-radius: 10px;
}

button.submit-button:hover {
background-color: #45a049; /* Darker Green */
}


@media (max-width: 480px) {
.filter-container{
  display: flex;
  flex-direction: column;
}
.filter-container > select,
.filter-container > input {
  width: 100%;
}

.download-selection-button {
  width: 100%;
  margin-left: 0;
}

.report-bug-button {
  width: 100%;
  margin: 5px 0;
}

.question-block {
  padding: 10px;
}

.question-block img {
  max-width: 100%;
}

.option {
  padding: 10px;
  font-size: 14px;
}

.quiz_container{
  margin-left: 0px;
}
}




.instructions {
text-align: center;
font-size: 18px;
color: #333;
padding: 20px;
background-color: #f7f7f7;
border: 2px dashed #ccc;
border-radius: 8px;
}


/* Global styles for thin scrollbar in Webkit browsers */
::-webkit-scrollbar {
  width: 2px; /* Width of the vertical scrollbar */
  height: 2px; /* Height of the horizontal scrollbar */
}


::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888; /*Color of the scrollbar thumb*/
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}


/* Global styles for thin scrollbar in Firefox */
html {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Color of the scrollbar thumb and track */
}
