/* Container styling */
.marker-homework-container {
  max-width: 100%;
  padding: 20px;
  background-color: #fef1be;
  background-image: url('../../../../../public/bg7.jpg'); 
  background-size:auto;
  background-position: center;
  background-repeat: repeat;
  background-attachment: fixed;
  /* height:90vh;
  position: fixed; */
  min-height: 90vh; /* Allows it to expand if content requires more space */
  display: flex; /* Flexbox for layout */
  flex-direction: column;
  overflow: hidden; 
}

/* Course heading */
.marker-course-heading {
  /* font-size: 2rem; */
  font-weight: bold;
  margin-bottom: 20px;
  color: #004aad; /* Blue to match header/nav bar */
  text-align: center; /* Center heading */
  border-bottom: 4px solid #ffcc00; /* Yellow underline */
  padding-bottom: 10px;
  margin-left: 20px;
}

/* Error message */
.marker-error-message {
  color: red;
  font-weight: bold;
  text-align: center;
}

/* Homework table styling */
.marker-homework-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* overflow: hidden; */
  overflow-y: auto;
  max-height: 100%;
}

.marker-homework-table-container{
/* width: 100%;
overflow-x: auto;  */
margin-top: 10px;
max-height: calc(90% - 70px);
overflow-y: auto; 
padding-right: 20px;
}

/* Table headers */
.marker-homework-table th {
background-color: #004aad; /* Blue background for table header */
color: white;
padding: 12px 15px;
font-weight: bold;
text-transform: uppercase;
}


.marker-homework-table tbody tr {
transition: background-color 0.3s ease;
}

.marker-homework-table tbody tr:hover {
background-color: #e2e8f0; /* A soft hover effect */
}

/* Button styling */
.marker-show-homework-btn {
padding: 10px 15px;
background-color: #ffcc00; /* yellow button color */
color: black;
border: none;
border-radius: 5px;
cursor: pointer;
font-size: 1rem;
transition: background-color 0.3s ease;
margin-left: 5px; margin-right: 5px;
}

.marker-show-homework-btn:hover {
background-color: #cca713; /* Darker yellow on hover */
}

.marker-homework-table td {
padding: 12px 15px;
border: 1px solid #ddd;
text-align: center;
}
.marker-homework-col3 {
text-align: left !important; 
}

/* Responsive Design */
@media (max-width: 768px) {
.marker-homework-container{
  width: 100%;
}
.marker-course-heading {
  font-size: 1.5rem;
}

.marker-homework-table th, .marker-homework-table td {
  font-size: 0.9rem;
  padding: 10px;
  text-align: center;
}

.marker-show-homework-btn {
  padding: 8px 12px;
  font-size: 0.9rem;
  margin-top: 10px;
}
}
