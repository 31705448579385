.statusCount-table-container {
    max-width: 100%;
    padding: 20px;
    background-color: #fef1be;
    background-image: url('../../../../../public/bg7.jpg'); 
    background-size:auto;
    background-position: center;
    background-repeat: repeat;
    background-attachment: fixed;
    /* height:90vh; */
    /* position: fixed; */
    min-height: 90vh; /* Allows it to expand if content requires more space */
    display: flex; /* Flexbox for layout */
    flex-direction: column;
    overflow: hidden; 
  }
  
  /* Heading styling */
  .statusCount-table-container h2 {
    text-align: center;
    color: #004aad;
    border-bottom: 4px solid #ffcc00;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .statusCount-table-wrapper {
    margin-top: 10px;
    max-height: calc(90% - 100px);
    overflow-y: auto; 
    /* padding-right: 20px; */
    flex-grow: 1;
  }

  /* Table styling */
  .statusCount-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    border-radius: 10px;
    overflow-y: auto;
    max-height: 100%;
  }

  .statusCount-table th {
    background-color: #004aad;
    color: white;
    padding: 12px 15px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .statusCount-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
  }
    
  /* Highlight rows where pending > 0 */
  .highlight-pending {
    background-color: #ff9999;
    /* font-weight: bold; */
  }
  
  .statusCount-table tbody tr:hover {
    background-color: #e2e8f0;
    cursor: pointer;
  }
  
  /* Loading and error messages */
  .statusCount-loading, .statusCount-error {
    text-align: center;
    margin-top: 20px;
  }
  
  .statusCount-error {
    color: red;
    font-weight: bold;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .statusCount-table th, .statusCount-table td {
      font-size: 0.9rem;
      padding: 10px;
    }
  
    .statusCount-table-container h2 {
      font-size: 1.5rem;
    }
  }
  