
.physics-qp-container {
  display: flex;
  height: 100vh; /* Adjust this as needed */
  width: 100%;
}
.physics-qp-main-container{
  display: flex;
  /* flex-direction: column; */
  width: 100%;
}

.filter-container_qp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filter-container_qp > select {
  width: 220px;
  border-radius: 30px;
  height: 25px;
  margin: 5px;
}

.filter-container_qp > input {
  width: 220px;
  margin: 5px;
  border-radius: 30px;
  height: 25px;
}
.menu_outer_container {
  width: 100%;
}
/* Sidebar styling */
.pq-sidebar {
  font-size: 5px !important;
  flex: 0 0 auto;
  font-family: 'Montserrat', sans-serif;
  overflow-y: auto;
  overflow-x: auto;
  position: fixed;
  top: 0; /* Stick the sidebar to the top of the container */
  scrollbar-width: thin; /* Make the scrollbar thin */
  scroll-behavior: smooth;
  flex-wrap: wrap;
  width: 10%;
  cursor: default;
}
.sidebar_header {
  font-size: 12px;
  cursor: default;
}
.checkbox_plus_sidebar_header {
  display: flex;
  flex-direction: row;
  cursor: default;
  align-items: flex-start;
}
.sidebar .filter-container {
  white-space: normal; /* Allow text to wrap */
  padding: 10px;
  cursor: default;
}

/* Sidebar header */
.sidebar-header {
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  cursor: default;
  /* overflow-y: auto; */
}



/* Sidebar menu */
.menu {
  padding: 10px;
  width: 40%;
}

/* Menu item */
.menu-item {
  padding: 10px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  transition: background-color 0.3s ease;
}



/* Search input */
.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
}

/* Select dropdown */
.select-dropdown {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  background-color: #546e7a; /* Dropdown background color */
  color: #fff; /* Dropdown text color */
}

.view_pdf {
  background-color: #e4c44e;
  border-radius: 5px;
  border-color: #e4c44e;
  align-content: space-between;
  cursor: pointer;
  align-items: center;
  margin: auto;
}


.header_plus_view_pdf_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  /* margin:10px 0 10px 0; */
}
.download_button {
  background-color: #e4c44e;
  height: 40px;
  border-radius: 10px;
  margin: 20px;
  font-weight: 600;
}

/* PDF viewer container */
.pdf-viewer-container {
  flex: 1;
  overflow-x: hidden; /* Enable horizontal scrolling if content overflows */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  height: 100%; /* Fill the remaining height */
}

.pdf-viewer-container > div {
  width: auto; /* Allow PDF viewer to resize horizontally */
  height: 100%;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 20px; /* Adjust padding as needed */
  transition: 0.3s;
  height: auto; /* Allow buttons to adjust height based on content */
  min-width: 100px; /* Set minimum width for buttons */
  font-size: 16px; /* Adjust font size as needed */
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: #ccc;
}

.tab button:hover {
  background-color: #e0e0e0; /* Light gray background on hover */
}

.pdf-viewer-container {
  flex: 1;
  display: flex;
  flex-direction: column; /* Change to column layout */
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
}

.pdf-viewer-container > div {
  max-width: 100%; /* Ensure PDF viewer fills container width */
}

.answers-tab-content {
  display: none; /* Hide answers tab content by default */
  padding: 20px; /* Add padding for content */
}

.answers-tab-content.active {
  display: flex; /* Show answers tab content when active */
}

/* Main content area styles */
.main-content_qp {
  display: flex;
  flex-direction: column;
  width: 95%;
  /* flex-grow: 1;  */
  padding: 10px; /* Padding around the content */
}

/* Tab navigation styles */
.tab-navigation {
  display: flex; /* Display tabs in a row */
  margin-bottom: 10px; /* Margin below the tabs */
}

.tab {
  padding: 10px; /* Padding for each tab */
  cursor: pointer; /* Cursor as pointer for clickable tabs */
  border-radius: 4px; /* Slightly rounded corners */
  margin-right: 5px; /* Space between tabs */
  background-color: #e0e0e0; /* Light gray background */
  transition: background-color 0.3s; /* Smooth transition for background color */
  border: none; /* Remove default button border */
}

.tab.active {
  background-color: #b0b0b0; /* Darker gray for active tab */
}

/* PDF viewer container */
.pdf-viewer-container {
  height: calc(100% - 20px); /* Full height minus padding */
  width: 100%; /* Full width */
  overflow: hidden; /* Prevent overflow */
}

.qp-questions-content,
.qp-answers-content {
  /* Add padding and dimensions to viewer */
  height: calc(100% - 20px);
  width: 100%;
  padding: 10px;
}

/* .questions-content .Viewer,
.answers-content .Viewer {
  height: 100%;Please sele
  width: 100%;
} */

.no-pdf-message {
  text-align: center;
  display: flex;
  justify-content: center; 
  margin-top: 200px;
  /* margin-left: 160px; */
  align-items: center; /* Center vertically */
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 20px;
}



/* CSS styles for the modal */


.download_pdf_button {
  height: 30px;
  margin: 5px;
  /* color: white; */
  font-weight: 30px;
  background-color: #3a86ff;
  border-radius: 5px;
  border-color: #3a86ff;
}

/* CSS styles for the scroll down message */
.scroll-down-message {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  color: #333;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.scroll-down-message:before {
  content: '⬇️';
  margin-right: 5px;
}

.report_bug_button {
  top: 20px;
  right: 20px;
  z-index: 9999; /* Ensure the button stays on top of other elements */
  padding: 15px; /* Adjust padding as needed */
  height: 50px;
  margin: 15px;
  background-color: #007bff; /* Add background color */
  border: none; /* Remove border */
  border-radius: 10px; /* Rounded corners */
  color: #fff; /* Text color */
  cursor: pointer; /* Cursor style */
  font-size: 16px; /* Font size */
  font-weight: 600; /* Font weight */
  transition: background-color 0.3s; /* Smooth transition for background color */
  display: flex;
  align-items: center;
  justify-content: center; /* Ensure proper centering */
}

.report_bug_button:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}


.pdf-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.clear-selection-button {
  background-color: #f44336; 
  color: white;
  padding: 10px 20px; 
  border: none; 
  border-radius: 4px; 
  cursor: pointer;
  margin-left: 10px; 
}

.clear-selection-button:hover {
  background-color: #d32f2f;
}

.download-selection-button {
  background-color: #2ad300; 
  color: white;
  padding: 10px 20px; 
  border: none; 
  border-radius: 4px; 
  cursor: pointer;
  margin-left: 10px; 
}

.download-selection-button:hover {
  background-color: #1d9200;
}


.sidebar {
  width: 250px; /* Define the width of the sidebar */
  padding: 10px;
  background-color: #f0f0f0;
  overflow-y: auto; /* Enable scrolling if needed */
}

.filter-container_qp {
  margin-bottom: 10px; /* Add some space between the filter and the menu */
}

.sidebar p,
.sidebar select,
.sidebar button {
  word-wrap: break-word; 
  margin-bottom: 10px; 
  font-size: 15px;
}

.sidebar_header {
  max-width: 100%; /* Ensures the text element takes up the full width of the container */
  word-wrap: break-word; /* Allows words to wrap when they reach the edge of the container */
  overflow-wrap: break-word; /* Same effect as word-wrap, ensures text wraps */
  white-space: normal; /* Ensures the text does not stay on one line */
  flex-grow: 1;
  overflow-x: auto;
  /* margin: 0 10px 10px 0; */
}

.menuItem{
  margin-top: 20px;
  margin-bottom: 20px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  /* padding:5px !important; */
  display: flex;
  flex-direction: column;
  margin: 10px;
  /* padding: 15px; */
  border: 2px solid #004aad;
}


.checkbox{
  margin-right: 10px;
  margin-top: 30px;
}


.selected-pdfs-list {
  background-color: #e9ecef;
  margin-top: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  margin-top: 15px;
}

.selected-pdf-item {
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  padding: 0px;
  text-align: end;
}

.toggle-button {
  margin: 10px;
}


.selected_sidebar{
  background-color:#f8f9fa ;
  border: 3px solid f8f9fa;
  width: 190px;
  /* width: 100%; */
  font-family: 'Montserrat', sans-serif;
  overflow-y: auto;
  word-wrap: break-word;
  padding: 0px;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  word-wrap: break-word;
  margin: 0px;
  flex-direction: column;

}
.selected_sidebar h2{
  margin-left: 2px;
  padding: 0px;
  
}

.selected_pdf_header {
  margin: 15px;
  text-align: left;
  font-size: 10px;

}
.selected_pdf_header p{
  margin: 0px;
  text-align: left;
  font-size: 15px;
  font-weight: 400;

}

.selected-pdfs-list{
  text-align: left;
}

.Selected_pdf_command{
  font-size: 15px;
  margin: 10px;
  padding: 30px;
  word-wrap: break-word;
  text-align: center;
  margin-top: 15px;
}



@media only screen and (max-width: 1000px) {
  .physics-qp-main-container{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  /* .navbar{
    width: 100%;
  } */
  .sidebar {
    width: 0%;
    position: relative; 
    top: auto;
    left: auto;
    margin-bottom: 10px;
  }

  .main-content {
    width: 100%; /* Adjust main content width to 100% */
    padding: 0 10px; /* Add padding to main content */
    height: 85vh;
  }

  .tab-navigation {
    flex-direction: column; /* Change tab navigation to vertical */
  }

  .tab {
    margin-bottom: 5px; /* Add spacing between vertical tabs */
  }

  .selected_sidebar{
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 180px;
    min-height:150px;
  }

  .filter-container_qp {

    display: inline;
    align-items: stretch; /* Stretch items vertically */
  }
  
}