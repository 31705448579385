.vpf-container {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  width: 100vw;
  background-color: #f0f0f0;
  padding-left:15px;
}

.vpf-header {
  background-color: #2c3e50;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.vpf-control-panel {
  display: flex;
  gap: 15px;
  align-items: center;
  color: white;
}

.vpf-title {
  font-size: 1.2rem;
  color: white;
  margin-right: 20px;
}

.vpf-control-panel svg {
  cursor: pointer;
  font-size: 1.5rem;
}

.vpf-body {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.vpf-sidebar {
  width: 100px;
  background-color: #f5f5f5;
  overflow-y: auto;
  padding: 10px;
}

.vpf-thumbnails {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.vpf-thumbnail {
  cursor: pointer;
  width: 100%;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.vpf-thumbnail:hover {
  border-color: #007bff;
}

.vpf-main {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  padding: 20px;
  background-color: #333;
}

.vpf-pdf-container {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.vpf-canvas {
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  /* Keep the canvas size fixed until zoom is applied */
  height: 100%;
}


.vpf-heading {
  font-weight: bold;
  margin-bottom: 20px;
  color: #004aad; /* Blue to match header/nav bar */
  text-align: center; /* Center heading */
  border-bottom: 4px solid #ffcc00; /* Yellow underline */
  padding-bottom: 10px;
  margin-left: 20px;
}

.vpf-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.vpf-info-group {
  display: flex;
  flex-direction: column;
}

.vpf-input:disabled, .vpf-textarea:disabled {
  background-color: #e0e0e0;
}

.vpf-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #004aad;
}

.vpf-input, .vpf-textarea {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.vpf-textarea {
  height: 100px;
}

/* Container for both canvas and iframe */
.canvas-iframe-container {
  display: flex;
  flex-direction: column; /* Stack vertically by default (for mobile) */
  width: 100%;
  height: 100%;
}

/* Canvas section */
.canvas-section {
  flex: 1;
  margin-right: 20px; /* Add spacing between the canvas and iframe */
}

/* Iframe section */
.iframe-section {
  flex: 1;
}

.sst-preview-pdf {
  width: 100%;
  height: 90%;
  border: none;
}

/* Responsive layout: Side by side on larger screens */
@media screen and (min-width: 768px) {
  .canvas-iframe-container {
    flex-direction: row; /* Change to side by side layout */
    height: 90vh;
  }
  
  .canvas-section, .iframe-section {
    width: 48%; /* Give each section equal width */
  }
}

/* For mobile */
@media screen and (max-width: 767px) {
  .canvas-section, .iframe-section {
    width: 100%; /* Full width on mobile */
    height: 80%;
  }
}

.vf-close {
  padding: 12px 20px;
  background-color: #ffcc00; /* Blue background */
  color: black;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

/* Hover effect for submit button */
.vf-close:hover {
  background-color: #cca713; /* Darker blue on hover */
}
