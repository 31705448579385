/* Container for the entire QuizQs component */
.quizqs_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  max-width: 90%;
}

.quizqs_title {
  margin: 0;
  margin-bottom: 2px;
  border-bottom: 2px solid #c9a227; 
}

/* Wrapper for questions and solution sections, setting overall dimensions */
.quizqs_questions-solution-container {
  display: flex;
  width: 90%;
  height: 80vh;
  padding: 5px;
  overflow: hidden; /* Prevent scrolling for the entire container */
}

/* Styling for the questions container */
.quizqs_questions-container {
  flex: 1;
  padding-right: 10px;
  overflow-y: auto; /* Enable vertical scroll for questions */
  transition: width 0.3s ease; /* Smooth width transition */
}

/* Full-width style when the solution iframe is closed */
.quizqs_questions-container.full-width {
  flex: 1; /* Expand to full width */
}

/* Half-width style when the solution iframe is open */
.quizqs_questions-container.half-width {
  width: 50%; /* Shrink to half width */
}

/* Solution iframe container styling */
.solution-iframe {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Enable vertical scroll for solution */
  /* max-height: 100%; */
}

/* Hide solution iframe when closed */
.solution-iframe.closed {
  display: none;
}

/* Show and expand solution iframe when open */
.solution-iframe.open {
  flex: 1;
}

/* Styling for the iframe itself */
.solution-iframe iframe {
  width: 100%;
  height: 100%;
  border: none;
  margin-top: 20px;
}

/* Styling for each question item */
.quizqs_question-item {
  padding: 15px;
  margin: 10px 0;
  width: 85%;
  box-shadow: 0 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Styling for question images */
.quizqs_question-image {
  max-width: 85%;
  /* height: auto; */
  border-radius: 4px;
  margin-bottom: 10px;
  display: block;
}
.quizqs_question-image.loading {
  background: url('../../public/Book.gif') center no-repeat; /* Placeholder loader */
  min-height: 200px;
}

/* Base styling for buttons */
.quizqs_button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth color transition */
}

/* Hover state for buttons */
.quizqs_button:hover {
  background-color: #0056b3;
}

.quizqs_zoom-controls {
  display: flex;
  /* justify-content: center; */
  margin-top: 10px;
}

.quizqs_zoom-button {
  background-color: rgb(215, 211, 211); /* Semi-transparent blue */
  color: black;
  border: none;
  border-radius: 50%; /* Circular buttons */
  padding: 10px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.quizqs_zoom-button:hover {
  background-color: rgba(0, 123, 255, 1); /* Solid blue on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.quizqs_zoom-button:focus {
    outline: none; /* Remove outline on focus */
}


.quizqs_qsn_close {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin: 10px 0; 
  width: 100%;
}

.quizqs_qsn_close p {
  margin: 5px; 
}

.-button {
  background-color: rgb(189, 186, 186); /* Semi-transparent blue */
  color: black;
  border: none;
  border-radius: 50%; /* Circular buttons */
  padding: 10px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.quizqs_zoom-button:hover {
  background-color: rgba(0, 123, 255, 1); /* Solid blue on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.quizqs_zoom-button:focus {
  outline: none; /* Remove outline on focus */
}

.quizqs_pagination {
  display: flex;
  justify-content: center; /* Center items */
  align-items: center; /* Center vertically */
  margin-top: 20px; /* Space above */
  font-size: 1.1rem; /* Font size for better visibility */
}

.quizqs_button {
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
  display: flex; /* Use flex for button layout */
  align-items: center; /* Center items vertically */
  margin: 0 5px; /* Space between buttons */
}

.quizqs_button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: translateY(-2px); /* Slightly lift the button on hover */
}

.quizqs_page-info {
  margin: 0 10px; /* Space around page info */
  font-weight: bold; /* Bold text for emphasis */
}


/* Base styles for mobile devices */
@media (max-width: 767px) {
  .quizqs_container {
    max-width: 100%;
    margin: 10px;
  }

  .quizqs_questions-solution-container {
    flex-direction: column; /* Stack questions and solution sections vertically */
    height: auto; /* Adjust height for smaller screens */
    overflow: visible; /* Allow content to scroll */
  }

  .quizqs_questions-container,
  .solution-iframe {
    width: 100%; /* Full width for each section */
    padding: 5px;
  }

  .quizqs_zoom-controls {
    justify-content: space-around; /* Center zoom controls */
    margin-top: 5px;
  }

  .quizqs_zoom-button {
    padding: 8px; /* Reduce padding */
    margin: 0 3px;
  }

  .quizqs_pagination {
    font-size: 1rem;
    margin-top: 15px;
  }

  .quizqs_button {
    padding: 8px 10px;
    font-size: 0.9rem; /* Smaller text on buttons */
  }
}

/* Tablet devices */
@media (min-width: 768px) and (max-width: 1024px) {
  .quizqs_container {
    max-width: 95%;
  }

  .quizqs_questions-solution-container {
    flex-direction: row;
    width: 100%;
    height: 75vh;
  }

  .quizqs_questions-container {
    width: 60%;
  }

  .solution-iframe {
    width: 40%;
  }

  .quizqs_question-item {
    width: 95%; /* Make question items wider */
  }

  .quizqs_button {
    padding: 10px 12px;
    font-size: 1rem;
  }

  .quizqs_zoom-button {
    padding: 10px;
    margin: 0 4px;
  }

  .quizqs_pagination {
    font-size: 1.05rem;
    margin-top: 18px;
  }
}
