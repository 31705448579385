.quiz-home-container {
  position: relative; /* Allow absolute positioning of the button */
}

.qh-quiz-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 20px;
  justify-content: center; /* Center the flex items */
}


.qh-exam-board {
  border: 1px solid #413f3f; /* Softer border */
  border-radius: 12px; /* More rounded corners */
  padding: 15px; /* Decreased padding for a smaller size */
  flex: 1 1 240px; /* Decrease the base size */
  min-width: 240px; /* Reduced minimum width */
  background-color: #ffffff; /* White background for contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.2s; /* Smooth hover effect */
  max-width: 750px;
}

.qh-exam-board:hover {
  transform: translateY(-2px); /* Add a lift effect on hover */
}


.qh-exam-board-title {
  font-size: 1.6em;
  margin-bottom: 10px;
  text-align: center;
  color: #2c3e50; /* Darker color for better readability */
}

.qh-level-container {
  margin-top: 15px;
}

.qh-subject {
  border-top: 2px solid #3498db; /* Bold border for separation */
  padding-top: 10px;
  margin-top: 10px;
  height: 80vh; /* Fixed height for subject container */
  overflow-y: auto; /* Enable vertical scrolling */
}

.qh-subject-title {
  font-size: 1.3em;
  margin-bottom: 5px;
  color: #2980b9; /* Color to indicate subjects */
  position: sticky; /* Make the title sticky */
  top: 0; /* Stick to the top of the subject container */
  background-color: #f9f9f9; /* Background color to overlay other content */
  z-index: 1; /* Ensure it appears above other content */
}


.qh-categories {
  list-style-type: none;
  padding-left: 0;
  margin-top: 5px;
}

.qh-category-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 8px; /* Add padding for better click area */
  border-radius: 5px; /* Rounded corners for the category items */
  background-color: #ecf6fc; /* Light background for categories */
  transition: background-color 0.2s; /* Smooth background transition */
  cursor: pointer;
}

.qh-category-item-lock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 8px; 
  border-radius: 5px; 
  background-color: #ecf6fc;
  transition: background-color 0.2s; 
  cursor: pointer;
}

.qh-category-item:hover {
  background-color: #d6eaf8; /* Highlight on hover */
}

.qh-category-item-label {
  font-weight: bold;
  color: #333; /* Standard text color */
}

.qh-button {
  position: absolute; /* Position the button absolutely */
  top: 17px; /* Adjust distance from the top */
  right: 50px; /* Adjust distance from the right */
  padding: 10px 20px; /* Add padding for better appearance */
  background-color: #007bff; /* Initial background color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for effects */
  animation: pulse 1.2s infinite; /* Pulsating effect */
}

/* Pulsating animation */
@keyframes pulse {
  0% {
    transform: scale(1);
    background-color: #007bff; /* Initial color */
  }
  50% {
    transform: scale(1.05); /* Slightly enlarge the button */
    background-color: #0056b3; /* Darker shade on pulse */
  }
  100% {
    transform: scale(1); /* Return to original size */
    background-color: #007bff; /* Return to initial color */
  }
}

/* Hover effects */
.qh-button:hover {
  transform: scale(1.1); /* Enlarge button on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Enhance shadow on hover */
  background-color: #0056b3; /* Change color on hover */
}

/* Additional button text style */
.qh-button span {
  font-weight: bold; /* Bold text */
  transition: color 0.3s ease; /* Smooth color transition */
}

/* Change text color on hover */
.qh-button:hover span {
  color: #ffe600; /* Change text color on hover */
}

/* Mobile styles */
@media (max-width: 600px) {
  .quiz-home-container {
    padding-top: 10px; 
  }

  .qh-button {
    top: 10px; 
    right: 10px; 
    font-size: 14px;
    padding: 8px 16px;
  }
}