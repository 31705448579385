
html, body {
  margin: 0;
  padding: 0;
  max-width: 100%;
}
.footer_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  background: black;
  overflow-x: hidden;
  overflow-y: hidden;
}

.footer {
  width: 100%;
  /* max-width: 1200px; */
}
.Links_container p {
  font-size: 15px;
}

.Links_container{
  margin: 0px;
}

.footer_content  {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  width: 100%;
 
  /* padding: 30px; */
  color: white;
}

.footer_quicklinks,
.footer_contacts {
  flex: 1;
  margin: 0 10px;
  max-height: 200px;
  margin-top: 30px;
}

.footer_quicklinks {
  display: flex;
  flex-direction: row;
}

.footer_quicklinks img {
  max-width: 180px;
  margin-bottom: 40px;
  margin-top: 30px;
  margin-right: 100px;
}

.footer_quicklinks p,
.footer_contacts p {
  margin: 5px 0;
}

.footer_contacts {
  color: black;
  float: right;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #3a0ca3;
  border-radius: 20px;
  padding: 20px;
  text-align: right;
}

.footer_contacts p {
  color: white;
  font-family: "Bitter", serif;
  width: 100%;
  margin-bottom: 5px;
  text-align: center;
}

#email {
  margin-right: 5px;
}

.call_icon {
  margin-right: 5px;
}

.call_footer {
  display: flex;
  margin: 10px;
}

.call_footer svg path {
  fill: white;
}

#email svg {
  fill: white; /* Change the fill color to white */
}

.call_footer {
  display: flex;
  align-items: center;
}

.call_footer svg {
  margin-right: 10px;
}

.call_footer a {
  color: white;
  text-decoration: none;
}

.footer_copyright {
  text-align: center;
  padding: 10px 0;
  width: 100%;
  color: white;
  overflow: hidden;
}

.Links_container a {
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.Links_container a:hover {
  color: #ffc300;
}

@media (max-width: 768px) {
  .footer_container {
    margin-top: 50px;
    width: 110%; 
  }

  .footer_content {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .footer_quicklinks,
  .footer_contacts {
    flex: none;
    width: 100%; 
    margin: 10px 0;
  }

  .footer_quicklinks {
    flex-direction: column;
    align-items: center;
  }

  .footer_quicklinks img {
    margin: 20px 0;
    max-width: 150px;
  }

  .footer_contacts {
    text-align: center;
    padding: 10px;
    margin: 0 20px;
    box-sizing: border-box;
    width: auto; /* Remove fixed width */
    max-width: 80%; /* Limit maximum width */
  }

  .footer_contacts p {
    /* width: 100%; */
    text-align: center;
  }

  .call_footer {
    display: flex;
    justify-content: center;
    flex-direction: column; /* Stack icon and number vertically */
    align-items: center; /* Center align the items */
  }

  .call_footer svg {
    margin: 5px 0; /* Add margin between icon and number */
  }

  .call_footer a {
    color: white;
    text-decoration: none;
    display: block; /* Ensure link takes full width */
    text-align: center;
  }

  #email {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
  }
}



@media (max-width: 768px) {
  .footer_container {
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    justify-content: center;
  }

  .footer_content {
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    justify-content: center;
  }

  .footer_quicklinks,
  .footer_contacts {
    flex: none;
    width: 100%;
    max-height: none;
    margin: 10px 0;
  }

  .footer_quicklinks {
    flex-direction: column;
    align-items: center;
  }

  .footer_quicklinks img {
    max-width: 100px;
    margin: 20px 0;
  }

  .footer_contacts {
    float: none;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
  }

  .footer_contacts p {
    width: 100%;
    text-align: center;
    font-size: 15px;
  }

  .call_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row; /* Ensure the icon and text are on the same line */
    margin: 10px 0;
  }

  .call_footer svg {
    margin-right: 10px; /* Space between the icon and the text */
  }

  .call_footer a {
    margin-top: 0; /* Remove margin to ensure it's aligned properly */
  }
}