.report-bug-form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.report-bug-form-container h2 {
  margin-bottom: 20px;
}

.report-bug-form-container form label {
  display: block;
  margin-bottom: 10px;
}

.report-bug-form-container form input,
.report-bug-form-container form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.report-bug-form-container form button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.report-bug-form-container form button:hover {
  background-color: #0056b3;
}
