/* Base styles */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}

.navbar {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #004aad; /* Navbar color */
  color: white;
  margin: 0 auto;
  height: 50px;
}

.logo-container {
  display: flex;
  align-items: center;
  color: white;
  margin-left: 40px;
  text-decoration: none;
}

.logo-text {
  color: white;
}

.logoImg {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.hamburger-icon {
  cursor: pointer;
  display: flex;
  font-size: 24px;
}

/* Sidebar styles */
.nav-sidebar {
  position: fixed;
  top: 0;
  right: -100%; /* Start off-screen on the right */
  width: 250px;
  height: 100%;
  background-color: #004aad; /* Same color as navbar */
  z-index: 999;
  transition: right 0.3s ease; /* Slide in from right */
  padding-top: 50px;
}

.nav-sidebar.open {
  right: 0; /* Slide into view from the right */
}

.sidebar-header {
  display: flex;
  align-items: center;
  padding: 15px;
  color: white;
}

.user-icon-sidebar {
  font-size: 50px;
  margin-right: 10px;
}

/* Username styling inside the sidebar */
.user-info p {
  color: white;  /* White text */
  font-size: 16px; /* Adjust as needed */
  margin-top: 10px;
  text-align: center;
}

.sidebar-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.sidebar-links li {
  margin: 20px 0;
}

.sidebar-links a, .sidebar-links button {
  text-decoration: none;
  color: white;
  background: none;
  border: none;
  font-size: 18px;
  width: 100%;
  display: block;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.sidebar-links a:hover, .sidebar-links button:hover {
  background-color: #003c8f; /* Slightly darker hover */
}

/* Close sidebar button */
.close-sidebar {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background-color: #ffbe0b;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 20px;
}

.close-sidebar span {
  font-weight: bold;
  color: #2c3e50;
}

/* Main Logo Container */
.logo-container {
  display: flex;
  align-items: center;
  margin-left: 40px;
  color: white;
  text-decoration: none;
}

.logo-text {
  color: white;
}

p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 20px;
  color: white;
}

.logoImg {
  width: 60px;
  height: auto;
  margin-right: 10px;
}

/* Hamburger icon initially hidden on desktop */
.hamburger-icon {
  display: none;
  cursor: pointer;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  display: inline-block;
  margin-left: 20px;
}

/* Login button style */
.btn-login {
  padding: 10px 20px;
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 40px;
}

/* Authentication buttons */
.authButtons {
  display: flex;
  font-weight: 800;
  gap: 20px;
}

.authButtons a {
  text-decoration: none;
  color: white;
  position: relative;
}

.authButtons a:not(:last-child)::after {
  content: '';
  position: absolute;
  background-color: #333;
  height: 12px;
  width: 1px;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.authButtons a:hover {
  color: #ffbe0b;
}

/* User Menu */
.user-menu {
  position: relative;
}

.user-icon {
  font-size: 24px;
  cursor: pointer;
  margin-left: 10px;
}

.user-icon:hover {
  font-size: 26px;
  color: #ffbe0b;
}

/* User menu options dropdown */
.user-menu-options {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: none;
}

.user-menu-options button {
  display: block;
  width: 100%;
  padding: 10px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

.user-menu-options button:hover {
  background: #f0f0f0;
}

.user-menu:hover .user-menu-options {
  display: block;
}

/* Responsive mobile styles */
/* Hide on mobile */
@media screen and (max-width: 768px) {
  .authButtons,
  .nav-links { 
    display: none; /* Hide all buttons and links on mobile */
  }

  .logo-container {
    margin-left: 10px; /* Adjust logo on mobile */
  }

  .hamburger-icon {
    display: flex;
    font-size: 28px;
    align-items: center;
    color: white;
    cursor: pointer;
    margin-right: 20px;
  }
}

/* Overlay for sidebar when open */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
}
