/* General Styling */
.about-us-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  text-align: center;
}

.about-us-heading {
  font-family: 'Merriweather', serif;
  font-size: 36px;
  color: #2c3e50;
  margin-bottom: 20px;
}

.about-us-description {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  color: #2c3e50;
  line-height: 1.8;
  text-align: justify;
  margin-bottom: 40px;
}

.slider-container {
  margin-top: 30px;
}

.slider-slide img {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 150px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.slider-slide img:hover {
  transform: scale(1.1);
}

.about-us-container::before {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  border: 2px solid #3498db;
  border-radius: 20px;
  z-index: -1;
}

@media (max-width: 768px) {
  .about-us-container {
    padding: 20px;
  }

  .about-us-heading {
    font-size: 28px;
  }

  .about-us-description {
    font-size: 16px;
  }

  .slider-slide img {
    max-width: 120px;
  }
}

@media (max-width: 480px) {
  .about-us-heading {
    font-size: 24px;
  }

  .about-us-description {
    font-size: 14px;
  }

  .slider-slide img {
    max-width: 100px;
  }
}