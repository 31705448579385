
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}

.testimonial_heading {
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  color: #ffb703;
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 1px;
}

.testimonial_card {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 350px;
  width: 100%;
  max-width: 300px;
  position: relative;
  font-family: "Inter", sans-serif;
  text-align: center;
  border: 4px solid black;
  box-shadow: 5px;
  border-radius: 12px;
  padding: 12px;
  margin: 12px;
}

.testimonials-container {
  position: relative;
}

.quotation_container {
  position: relative;
  padding: 15px;
  margin-bottom: 20px;
}

.testimonial_text {
  font-style: italic;
  font-family: "Dancing Script", cursive;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  position: relative;
  z-index: 1; /* Ensure the text is above the quotation marks */
}

.text_muted {
  font-weight: 900;
  text-align: center;
}

.quotation_mark {
  position: absolute;
  font-size: 5em;
  color: #ff9f1c;
  z-index: 0; /* Ensure the quotation marks are below the text */
}

.quotation_mark.left {
  top: -20px;
  left: -10px;
}

.quotation_mark.right {
  bottom: -60px;
  right: -15px;
}

.testimonial_card {
  position: relative;
  box-shadow: 10px 5px 5px 0 #0096c7; /* Apply a box shadow with gradient */
}

/* For phones */
@media only screen and (max-width: 768px) {
  .testimonial_card {
    width: 70%; /* Increase width for better fitting */
    margin: 12px auto;
    padding: 20px; /* Add more padding for better spacing */
  }

  .quotation_mark {
    font-size: 3em; /* Decrease font size for quotation marks */
    top: -10px; /* Adjust position for better fitting */
    bottom: -30px; /* Adjust position for better fitting */
  }

  .testimonial_text {
    font-size: 16px; /* Adjust font size for better readability */
  }
}

/* For tablets */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .testimonial_card {
    width: 80%;
    margin: 12px auto;
  }

  .quotation_mark {
    font-size: 3em;
  }

  .testimonial_text {
    font-size: 16px;
  }
}