/* AccessExamPaperMain.css */
.access-exam-container {
    text-align: center;
    margin-top: 50px;
  }
  
  .button-container {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .subject-button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .subject-button:hover {
    background-color: #45a049; /* Darker Green */
  }
  
  .sidebar {
    width: 250px; /* Define the width of the sidebar */
    padding: 10px;
    background-color: #f0f0f0;
    overflow-y: auto; /* Enable scrolling if needed */
  }

  .filter-container {
    margin-bottom: 10px; /* Add some space between the filter and the menu */
  }

  .sidebar p,
  .sidebar select,
  .sidebar button {
    word-wrap: break-word; 
    margin-bottom: 10px; 
    font-size: 15px;
  }

  .sidebar_header {
    max-width: 100%; /* Ensures the text element takes up the full width of the container */
    word-wrap: break-word; /* Allows words to wrap when they reach the edge of the container */
    overflow-wrap: break-word; /* Same effect as word-wrap, ensures text wraps */
    white-space: normal; /* Ensures the text does not stay on one line */
  }

  .menuItem{
    margin-top: 20px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* border: 2px solid green; */
  }