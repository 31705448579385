.login-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
}

.login-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-heading {font-family: "Playfair Display", serif;
text-align: center;
font-size: 36px;
color: #333;
margin-bottom: 20px;
color: #ffb703;
text-shadow: -1px -1px 0 #000,  
              1px -1px 0 #000,
             -1px  1px 0 #000,
              1px  1px 0 #000;
}

.login-container form {
  display: flex;
  flex-direction: column;
}

.login-container .form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.login-container label {
  flex: 1;
  margin-right: 10px;
  font-weight: bold;
}

.login-container input {
  flex: 2;
  padding: 8px; /* Decreased padding */
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  cursor: text
}


.login-container button {
  padding: 8px; /* Decreased padding */
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-container button:hover {
  background-color: #45a049;
}

.google-login {
  text-align: center;
  margin-top: 20px;
  justify-content: center;
}

.google-login button {
  padding: 8px; /* Decreased padding */
  background-color: #4285F4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.google-login button:hover {
  background-color: #357AE8;
}

.google-login p {
  margin: 10px 0;
}

.create-account {
  text-align: center;
  margin-top: 20px;
}

.create-account p {
  margin: 10px 0;
}

.create-account a {
  color: #4CAF50;
  text-decoration: none;
}

.create-account a:hover {
  text-decoration: underline;
}

.error-tag{
  color:red;
  font-size:smaller;
  font-style: italic;
  text-align: center;
}

.head-tag{
  color:orangered;
  font-size:smaller;
  font-style: italic;
  text-align: center;
}

.buttonStyle {
  display: flex;
  align-items: center;
  background-color: #4285F4;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.iconStyle {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}


.microsoft-login {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  /* height: 100vh; Ensures the container takes full viewport height */
}
.microsoft-login :hover {
  background-color: #357AE8;
}

.ms-buttonStyle {
  display: flex;
  align-items: center;
  background-color: #315389 !important;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.ms-iconStyle {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

/* svg path {
  fill: rgb(255, 255, 255); 
} */

/* Media Queries */
@media (max-width: 600px) {
  .login-container {
    padding: 10px;
  }

  .form-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-group label {
    margin-bottom: 5px;
  }

  .login-container h2 {
    font-size: 1.5em;
  }

  .login-container button {
    padding: 6px;
    font-size: 0.8em;
  }

  .google-login button {
    padding: 6px;
    font-size: 0.8em;
  }
}

.register-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; 
}


.register-spinner {
  border: 8px solid rgba(0, 0, 0, 0.1); 
  border-top: 8px solid #3498db; 
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: register-spin 1s linear infinite;
}

@keyframes register-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.login-register-container {
  text-align: center;
  padding: 20px;
}
.login-line {
  border-top: 2px solid #ddd;
  margin-bottom: 20px;
}

.login-info-text {
  font-size: 14px; 
  color: #333; 
  margin-bottom: 20px; 
}

.register-buttonStyle {
  font-size: 18px; 
  padding: 12px 24px; /* Adjust padding for a larger button */
  border: none;
  background: linear-gradient(135deg, #007bff, #0056b3); /* Gradient background */
  color: white;
  border-radius: 8px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
}

.register-buttonStyle:hover {
  background: linear-gradient(135deg, #0056b3, #003d7a); /* Darker gradient on hover */
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

.register-buttonStyle:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Add focus ring */
}