
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}

.classes_card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin: 20px;
  padding: 20px;

}

.classes_card_header {
  font-weight: 600;
  font-size: 40px;
  color: #ffb703;
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 1px;
  text-align: center;
  margin-bottom: 20px;
}

.single_card_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping of cards for better responsiveness */
}

.subject_card {
  width: 250px;
  height: 80%;
  border: 4px solid #333; /* Darker border color for better contrast */
  border-radius: 15px; /* Reduced border radius for a modern look */
  margin: 20px;
  padding: 20px;
  background-color: #fff; /* White background for cards */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.05); /* Enhanced shadow for depth */
  text-align: left; /* Align text to the left */
  transition: transform 0.2s; /* Smooth transition for hover effect */
}

.subject_card:hover {
  transform: translateY(-10px); /* Slight lift on hover */
}

.card_header {
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 10px;
  color: #333; /* Darker text color for the header */
  text-align: center; /* Center align card headers */
}

.card_text {
  font-size: 16px;
  color: black; /* Gray color for the text */
}

.card_title {
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 10px;
  color: #cca43b; /* Highlight color for the title */
  -webkit-text-stroke-color: black;
}

.card_text ul {
  padding-left: 20px; /* Increase padding for the list */
}

.card_text ul li {
  padding: 10px 0; /* Increase padding for each list item */
}

/* For phones */
@media only screen and (max-width: 768px) {
  .single_card_container {
    flex-direction: column;
    align-items: center;
  }
  .subject_card {
    width: 70%;
  }
}

/* For tablets */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .single_card_container {
    flex-direction: column;
    align-items: center;
  }
  .subject_card {
    width: 80%;
  }
}

.book_your_slots {
  margin-top: 30px;
}