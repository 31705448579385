

.button_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}


.button_container button {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: 10px;
  flex-direction: column;
  width: 200px;
  height: 60px;
  text-align: center;
  font-size: 20px; /* Fixed font size for consistency */
}

.revision-set_container{
  display: flex;
  flex-direction: column;
}



.single_card_img {
  display: block; /* Added */
  border-radius: 30px;
  border: 2px solid black;
  padding: 0px;
  margin: 0 auto; /* Added */
  height: 400px;
  width: 400px; /* Added */
}


.single_card_title{
    padding: 0px;
    margin: 2px;
    text-align: center;
    font-family: "Playfair Display", serif;
    font-size: 40px;
    font-weight: 600;
}
.single_revision_card {
  
  margin: 40px;
  align-items: center;
  padding: 29px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  /* border: 4px solid black; */
  width: 400px;
  height: 320px;
  border-radius: 30px;
}

.card-img-top {
  height: 200px; /* Adjust the height as needed */
  object-fit: cover;
}

.card_text {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.Resources_button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 5px;
  transition: background-color 0.3s ease;
}

.Resources_button:hover {
  background-color: #0056b3;
}

.subject_button {
  color: white; /* Button text color */
  text-decoration: none;
  transition: all 0.3s ease; /* Smooth transition effect */
  padding: 15px 30px; /* Adjust padding for button size */
  font-size: 20px; /* Adjust font size */
  border: 2px solid #007bff; /* Add border */
  border-radius: 5px; /* Add border radius */
  background-color:#007bff ;
  border-radius: 30px;
}

.subject_button:hover {
  color: #0056b3; 
  background-color: white; 
  border-color: #0056b3; 
  text-decoration: none; 
}


.access_exam_questions_btn {
  text-align: center; /* Center the button horizontally */
  margin-top: 20px; /* Add some top margin for spacing */
}

.access_exam_questions_btn button {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-image: linear-gradient(to right, #0077b6, #023e8a, #0096c7);
  color: white;
  border: none;
  width: 200px; /* Fixed width */
  height: 60px; /* Fixed height */
  border-radius: 5px;
  font-size: 20 px;
  cursor: pointer;
  transition: background-image 0.3s ease, transform 0.3s ease;
}

.access_exam_questions_btn button:hover {
  background-image: linear-gradient(to right,#0096c7, #023e8a, #0077b6); /* Change gradient colors on hover */
  transform: scale(1.05); /* Add a slight scale effect on hover */
}


/* For phones */
/* For phones */
@media only screen and (max-width: 768px) {
    .revision-set_container {
      /* padding: 10px;  */
      display: flex;
      flex-direction: column;
      overflow: hidden;
      justify-content: center;
      align-items: center;
    }
  
    .row {
      margin: 10px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      width: 100%; /* Adjust width as needed */
    }
  
    .single_card_img {
      width: 100%; 
    }
  
    .single_revision_card {
      width: 80%; /* Adjust width as needed */
    }
  }
  
  /* For tablets */
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    /* Your styles for tablets here */
  }