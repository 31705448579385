.tnc-overlay {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.tnc-popup {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
}

.tnc-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.tnc-message {
    margin-bottom: 1rem;
}

.tnc-content-box {
    overflow-y: auto;
    max-height: 240px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.tnc-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.tnc-button:hover {
    background-color: #0056b3;
}

.tnc-checkbox{
    margin-bottom: 20px;
}