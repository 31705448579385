/* Container for courses page (full-screen with background color) */
.sst-course-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align content to the left */
    min-height: 100vh; /* Full screen height */
    background-color: #ffffff; /* Light golden background color */
    padding: 40px 80px; /* More padding on the left for left alignment */
    background-image: url('../../../../public/bg7.jpg'); 
    background-size:auto;
    /* background-position: center; */
    /* background-repeat: repeat; */
    background-attachment: fixed;
    max-width: 100%;
  }
  
  /* Heading for the course page */
  .sst-course-container h1 {
    font-size: 2.8rem;
    color: #004aad; /* Dark blue text for heading */
    font-weight: bold;
    margin-bottom: 40px;
    letter-spacing: 2px;
    border-bottom: 4px solid #ffd700; /* Golden underline */
    display: inline-block;
    padding-bottom: 10px;
    text-align: left; /* Align heading to the left */
    width: 100%;
  }
  
  /* Course cards container */
  .sst-course-cards {
    display: flex;
    justify-content: flex-start; /* Align cards to the left */
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
  }
  
  /* Each course card */
  .sst-course-card {
    background-color: #fef1be;
    border-radius: 15px;
    padding: 30px;
    border: 1px solid #ddd;
    width: 250px; /* Adjusted width */
    height: 150px;
    text-align: left; /* Align card text to the left */
    cursor: pointer;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    /* color: #004aad; Blue text for cards */
    overflow-wrap: break-word; /* Ensure long text wraps inside the card */
  }

  /* .marker-courses-card:hover {
    transform: translateY(-15px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  } */
  
  
  .sst-course-card h2 {
    font-size: 2rem;
    color: #004aad; /* Matching dark blue text */
    margin: 0;
    font-weight: 300;
    font-family: Georgia, serif;
    letter-spacing: 1.5px;
    word-wrap: break-word; /* Ensure long words break properly */
    overflow-wrap: break-word; /* Ensure text doesn't overflow the card */
  }
  
  /* Hover effect for cards */
  .sst-course-card:hover {
    transform: scale(1.05);
    background-color: #e0f7fa;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    
    .sst-course-container {
      width: 70%;
    }
  
    .sst-course-cards {
      align-items: center;
    }
  
    .sst-course-card {
      width: 100%;
      max-width: 350px; /* Adjusted for smaller screens */
      height: auto; /* Auto height for better fit on mobile */
    }
  
    .sst-course-card h2 {
      font-size: 1.3rem; /* Slightly smaller font for mobile */
    }
  }
  