/* Container styling */
.marker-students-container {
  max-width: 100%;
  /* padding: 20px; */
  background-color: #fef1be;
  background-image: url('../../../../../public/bg7.jpg'); 
  background-size:auto;
  background-position: center;
  background-repeat: repeat;
  background-attachment: fixed;
  /* height:90vh;
  position: fixed; */
  min-height: 90vh; /* Allows it to expand if content requires more space */
  display: flex; /* Flexbox for layout */
  flex-direction: column;
  overflow: hidden; 
}

/* Heading */
.marker-students-heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #004aad; /* Blue to match theme */
  text-align: center; /* Center the heading */
  border-bottom: 4px solid #ffcc00; /* Yellow underline */
  padding-bottom: 10px;
  letter-spacing: 2px;
  font-family: 'Arial', sans-serif;
}

/* Error message */
.marker-error-message {
  color: red;
  font-weight: bold;
  text-align: center;
}


.marker-students-table-container {
  /* width: 100%; */
  overflow-x: auto;  
  margin-top: 10px;
  max-height: calc(90% - 70px);
  overflow-y: auto; 
  padding: 10px;
}

/* Students table styling */
.marker-students-table {
  width: 100%; /* Ensure the table takes full width */
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* overflow: hidden;
  margin-top: 20px; */
  overflow-y: auto;
  max-height: 100%;
  flex-grow: 1;
}

/* Table headers */
.marker-students-table th {
  background-color: #004aad; /* Blue background for table header */
  color: white;
  padding: 12px 15px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

/* Table rows and cells */
.marker-students-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
  white-space: nowrap; /* Prevent text wrapping for better readability */
  max-width: 300px; /* Set the desired max width */
  overflow-wrap: break-word; /* Allow text to break at word boundaries */
  word-wrap: break-word; /* For compatibility with older browsers */
  white-space: normal;
}


/* Hover effect for table rows */
.marker-students-table tbody tr {
  transition: background-color 0.3s ease;
}

.marker-students-table tbody tr:hover {
  background-color: #e2e8f0; /* A soft hover effect */
}

/* Button styling */
.marker-grade-btn {
  margin-left: 5px;
  padding: 10px 15px;
  background-color: #ffcc00; /* Blue button color */
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.marker-grade-btn:hover {
  background-color: #cca713; /* Darker blue on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  
  .marker-students-container{
    width: 100%;
  }

  .marker-students-heading {
    font-size: 1.5rem;
  background-color: #fef1be; /* Light golden background to match theme */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-image: url('../../../../../public/bg7.jpg'); 
  background-size:auto;
  background-position: center;
  background-repeat: repeat;
  background-attachment: fixed;
}
  
  /* .marker-students-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  } */

  .marker-students-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #074fad; /* Blue to match theme */
    text-align: center; /* Center the heading */
    border-bottom: 4px solid #ffcc00; /* Yellow underline */
    padding-bottom: 10px;
  }
  
  .marker-error-message {
    color: red;
    font-weight: bold;
  }
  
  .marker-students-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    /* overflow: hidden;
    margin-top: 20px; */
    overflow-y: auto;
    max-height: 100%;
    padding: 10px;
  }
  
  /* Table headers */
  .marker-students-table th {
    background-color: #004aad; /* Blue background for table header */
    color: white;
    padding: 12px 15px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    
  }
  
  /* Table rows and cells */
  .marker-students-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  /* Alternating row colors for readability */
  .marker-students-table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  .marker-students-table tbody tr:nth-child(even) {
    background-color: #fff;
  }
  
  /* Hover effect for table rows */
  .marker-students-table tbody tr {
    transition: background-color 0.3s ease;
  }
  
  .marker-students-table tbody tr:hover {
    background-color: #e2e8f0; /* A soft hover effect */
  }
  
  /* Button styling */
  .marker-grade-btn {
    padding: 10px 15px;
    background-color: #ffcc00; /* Blue button color */
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .marker-grade-btn:hover {
    background-color: #cca713; /* Darker blue on hover */
  }
  .disabled-btn {
    cursor: not-allowed;
  }
}
  