
.main_conatiner{
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

}

/* Container for the accordion */
.centered-container {
display: flex;
justify-content: center;
align-items: center;

width: 600px;
margin: 20px;

box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); /* Add shadow */
}

/* Accordion styling */
.accordion {
border: 2px solid #ff69b4; /* Change border color */
width: 400px; /* Adjust width as needed */
height: 100%; /* Stretch to full height of parent container */
}

.accordion__item {
border-bottom: 1px solid #e0e0e0;
border: 2px solid #ffd700; /* Change border color */
}

.accordion__button {
border: 2px solid #00fa9a; /* Change border color */
background-color: #f0f8ff; /* Change background color */
color: #333;
cursor: pointer;
padding: 18px;
width: 100%;
text-align: left;
border: none;
border-bottom: 1px solid #e0e0e0;
transition: background-color 0.3s ease;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow */
}

.accordion__button:hover {
background-color: #add8e6; /* Change background color on hover */
}

.accordian_item_heading {
font-size: 40px;
font-weight: 900;
color: #483d8b; /* Change text color */
}

.accordion__button:before {
display: inline-block;
content: "";
height: 10px;
width: 10px;
margin-right: 12px;
border-bottom: 2px solid currentColor;
border-right: 2px solid currentColor;
transform: rotate(-45deg);
transition: transform 0.3s ease;
}

.accordion__button[aria-expanded="true"]::before {
transform: rotate(45deg);
}

.accordion__panel {
padding: 20px;
background-color: #f0f8ff; /* Change background color */
border-bottom: 1px solid #e0e0e0;
width: 200px;
}
