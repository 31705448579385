/* Container for the entire marker courses section */
.marker-course-container {
  text-align: center;
  min-height: 100vh;
  background-color: #ffffff;
  padding:20px;
  background-image: url('../../../../../public/bg7.jpg'); 
  background-size:auto;
  /* background-position: center; */
  /* background-repeat: repeat; */
  background-attachment: fixed;
}

  /* Heading styles */
  .marker-courses-heading {
    font-size: 2.8rem;
    font-family: 'Arial', sans-serif; /* Clean sans-serif font */
    color: #004aad; /* Blue heading matching navbar */
    text-align: left;
    margin-left: 20px;
    
    font-weight: bold;
    border-bottom: 4px solid #ffcc00; /* Yellow underline */
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
  
  /* Courses list container */
  .marker-courses-cards {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 30px;
  }
  
  /* Course card styles */
  .marker-course-card {
    background-color: #fef1be;
    padding: 40px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 220px;
    height: 100px; /* Fixed height to avoid changing size */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.1s ease-in-out;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word; /* Ensure long words break within the card */
    white-space: normal; /* Allow text to wrap inside the card */
  }
  
  /* Hover effect on course cards */
  /* .marker-courses-card:hover {
    transform: translateY(-15px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  } */
  
  /* Course name inside card */
  .marker-course-card h3 {
    color: #004aad; /* Blue text */
    font-weight: 100;
    font-family: Georgia, serif;
    margin: 0;
    line-height: 1.2; /* Adjust line height to avoid large gaps */
    word-break: break-word; /* Ensure words break if they are too long */
  }

  .marker-course-card:hover {
    transform: scale(1.05);
    background-color: #e0f7fa;
}

/* Admin-specific course card */
.marker-course-card-admin {
  background-color: #fef1be;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 220px;
  height: 140px; /* Adjusted height for the vertical layout */
  display: flex;
  flex-direction: column;  /* Stack the sections in a column */
  justify-content: space-between;
  text-align: center; /* Align text to the center */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.1s ease-in-out;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal; /* Allow text to wrap inside the card */
  position: relative;  /* For absolute positioning of the icon */
}

/* Course name section (80%) */
.marker-course-card-admin .course-name {
  flex-grow: 1;  /* Use remaining space for the course name */
  color: #004aad;
  font-weight: 100;
  font-family: Georgia, serif;
  margin: 0;
  font-size: 20px;
  line-height: 1.2;
  word-break: break-word; /* Ensure long words break within the card */
}

/* Edit icon section (20%) */
.marker-course-card-admin .edit-icon-container {
  display: flex;
  justify-content: right; /* Center the icon */
  align-items: center;
  margin-top: 5px;
}

/* Rounded pencil icon styles */
.marker-course-card-admin .edit-icon {
  font-size: 15px;
  color: #004aad;
  cursor: pointer;
  border: 0.8px dotted gainsboro;
  border-radius: 50%; /* Make the border circular */
  padding: 8px; /* Space inside the icon border */
  /* background-color: #f3f0e4; */
  transition: background-color 0.2s ease;
  margin-left: 4px;
}

/* Hover effect for the edit icon */
.marker-course-card-admin .edit-icon:hover {
  background-color: #e0f7fa; /* Light background on hover */
}

/* Hover effect for the entire card */
.marker-course-card-admin:hover {
  transform: scale(1.05);
  background-color: #e0f7fa;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Center the loader vertically */
}

  
/* Responsive Design */
@media (max-width: 768px) {
  .marker-course-container{
    width: 100%;
  }
  .marker-courses-heading {
    font-size: 2rem;
    margin-left: 0;
  }

  .marker-courses-card {
    width: 100%;
    max-width: 350px;
  
  }

  .marker-courses-card h2 {
    font-size: 1.3rem;
  }
}
  