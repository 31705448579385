.quiz-set-solution {
    position: relative;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .quiz-set-solution .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    background: transparent;
    border: none;
    font-size: 26px;
    color: black;
    cursor: pointer;
  }
  
  .quiz-set-solution .close-button:hover {
    color: #555;
  }
  
  .quiz-set-solution .pdf-container {
    width: 100%;
    height: 85vh; /* Adjust height if needed */
    border: none;
    background-color: #f9f9f9; /* Customize background color */
    overflow: hidden; /* Hide overflow */
  }
  