/* Container for the submit homework section */
.submit-homework-container {
  padding: 20px;
  background-color: #ffff; /* Light golden background */
  min-height: 100vh; /* Full screen height */
  background-image: url('../../../../public/bg7.jpg'); 
  background-size:auto;
  background-position: center;
  background-repeat: repeat;
  background-attachment: fixed;
  
}


/* Heading style */
.submit-homework-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #004aad; /* Blue to match the website theme */
  text-align: left;
  margin-bottom: 30px;
  border-bottom: 3px solid #ffcc00; /* Yellow underline */
  padding-bottom: 10px;
  font-family: 'Arial', sans-serif;
  overflow-wrap: break-word; /* Ensure long text wraps inside the card */
}

/* Container for image preview section */
.sst-preview {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

/* Image container for individual previews */
.sst-image-container {
  position: relative;
  margin: 10px;
}

/* Preview image styling */
.sst-preview-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 2px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease-in-out;
}

/* Hover effect for images */
.sst-preview-image:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Remove button styling for images */
.sst-remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff4d4d; /* Softer red to match theme */
  color: white;
  border: none;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

/* Hover effect for remove button */
.sst-remove-btn:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

/* Generate and Submit button styling */
.sst-generate-btn {
  padding: 12px 20px;
  background-color: #ffcc00; /* Website theme's yellow color */
  color: black;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

/* Hover effect for submit button */
.sst-generate-btn:hover {
  background-color: #cca713; /* Darker yellow on hover */
}

/* Upload type options styling */
.upload-type-container {
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
}

.upload-type-option {
  margin: 0 10px;
  font-size: 1.2rem;
}

/* Comments section styling */
.comments-section {
  margin-top: 20px;
  text-align: left;
}

.comments-label {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  color: #004aad; /* Matching the blue theme */
  margin-bottom: 10px;
}

.comments-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  resize: vertical;
  background-color: #fff;
}

/* Styling for the file input */
.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  background-color: #004aad; /* Blue matching the website theme */
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.custom-file-upload:hover {
  background-color: #003580; /* Darker blue on hover */
}

/* Hide the native file input */
.upload-pdf-input {
  display: none;
}

/* Container for PDF preview */
.pdf-preview {
  margin-top: 20px;
  text-align: center;
}

.pdf-preview iframe {
  border: 2px solid #ddd;
  border-radius: 8px;
}


/* Styling for the file input (for images) */
.custom-file-upload-images {
  display: inline-block;
  padding: 10px 20px;
  background-color: #004aad; /* Blue matching the website theme */
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.custom-file-upload-images:hover {
  background-color: #003580; /* Darker blue on hover */
}

/* Hide the native file input for images */
.upload-image-input {
  display: none;
}

/* Preview container for images */
.sst-preview {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 10px; 
  background-color: #f9f9f9;
}

/* Preview image container */
.sst-image-container {
  position: relative;
  margin: 10px;
}

/* Preview image styling */
.sst-preview-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 2px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease-in-out;
}

/* Hover effect for images */
.sst-preview-image:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Hover effect for remove button */
.sst-remove-btn:hover {
  background-color: #d32f2f; /* Darker red */
}

/* Container for drag-and-drop interface */
.droppable-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border: 2px dashed #004aad; /* Dashed border to signify drop area */
  padding: 10px;
  border-radius: 8px;
  margin-top: 20px;
}

.droppable-placeholder {
  height: 150px;
  width: 150px;
}

.sst-pdf-container {
  position: relative;
  margin: 10px;
}
.sst-preview-pdf {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 10px; 
  border: 2px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease-in-out;
}
.sst-preview-pdf:hover {
  /* transform: translateY(-5px); */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}
.upload-note {
  font-weight: bold; /* Ensure bold text */
  font-size: 1.2rem; /* Increase font size slightly */
  color: #d32f2f; /* Bright red color for visibility */
  background-color: #fff3f3; /* Light red background for contrast */
  border: 2px solid #d32f2f; /* Red border for emphasis */
  border-radius: 5px; /* Smooth rounded corners */
  padding: 10px 15px; /* Add space around the text */
  text-align: center; /* Center-align the text */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  max-width: 400px; /* Limit width for readability */
  margin: 10px auto; /* Center the note horizontally with auto margins */
  display: inline-block; /* Adjust sizing based on content */
}




/* Responsive Design */
@media (max-width: 768px) {

  .submit-homework-container{
    width: 100%;
  }
  .submit-homework-heading {
    font-size: 2rem;
  }

  .sst-preview-image {
    width: 120px;
    height: 120px;
  }

  .sst-generate-btn {
    font-size: 0.9rem;
    padding: 10px 15px;
  }

  .comments-textarea {
    font-size: 0.9rem;
    padding: 8px;
  }

  .custom-file-upload-images {
    font-size: 0.9rem;
    padding: 10px 15px;
  }

}
