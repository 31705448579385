.edit-profile-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 0.9rem;
}

.edit-profile-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.edit-profile-heading {font-family: "Playfair Display", serif;
    text-align: center;
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
    color: #ffb703;
    text-shadow: -1px -1px 0 #000,  
                  1px -1px 0 #000,
                 -1px  1px 0 #000,
                  1px  1px 0 #000;
    }

.edit-profile-container form {
    display: flex;
    flex-direction: column;
}

.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.form-group label {
    flex: 1;
    margin-right: 10px;
    font-weight: bold;
}

.form-group input,
.form-group select {
    flex: 2;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.form-tnc{
    text-align: center;
    margin-bottom: 20px;    
}

.edit-profile-container button {
    padding: 8px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.edit-profile-container button:hover {
    background-color: #45a049;
}

.verification-message {
    color: #333; /* Dark red text */
    border: 1px solid ; 
    border-radius: 5px;
    padding: 15px;
    margin: 20px 0; /* Space around the message */
    display: flex; /* Align items in a row */
    justify-content: space-between; /* Space between text and button */
    align-items: center; /* Center items vertically */
    font-size: 0.8rem;
  }
  
  .resend-button {
    background-color: #007bff; 
    color: white;
    border: none; 
    border-radius: 5px; 
    padding: 10px 15px; 
    cursor: pointer; 
    transition: background-color 0.3s; 
  }
  
  .resend-button:hover {
    background-color: #0056b3; 
  }
  

/* Media Queries */
@media (max-width: 600px) {
    .edit-profile-container {
        padding: 10px;
    }

    .form-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .form-group label {
        margin-bottom: 5px;
    }

    .edit-profile-container h2 {
        font-size: 1.5em;
    }

    .edit-profile-container button {
        padding: 6px;
        font-size: 0.8em;
    }
}
