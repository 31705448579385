.register-container {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 0.9rem;
  }
  
  .register-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .register-heading {font-family: "Playfair Display", serif;
    text-align: center;
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
    color: #ffb703;
    text-shadow: -1px -1px 0 #000,  
                  1px -1px 0 #000,
                 -1px  1px 0 #000,
                  1px  1px 0 #000;
    }
  
  .register-container form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .form-group label {
    flex: 1;
    margin-right: 10px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group select {
    flex: 2;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .register-container button {
    padding: 8px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .register-container button:hover {
    background-color: #45a049;
  }
  
  .google-login {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
  }

  .microsoft-login {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    /* height: 100vh; */
  }
  
  .ms-buttonStyle {
    display: flex;
    align-items: center;
    background-color: #0078D4;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .ms-iconStyle {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
  
  .google-login button {
    padding: 8px;
    background-color: #4285F4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .google-login button:hover {
    background-color: #357AE8;
  }
  
  .google-login p {
    margin: 10px 0;
  }
  
  .login-link {
    text-align: center;
    margin-top: 20px;
  }
  
  .login-link p {
    margin: 10px 0;
  }
  
  .login-link a {
    color: #4CAF50;
    text-decoration: none;
  }
  
  .login-link a:hover {
    text-decoration: underline;
  }

  .buttonStyle {
    display: flex;
    align-items: center;
    background-color: #4285F4;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .iconStyle {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  .error-tag{
    color:red;
    font-size:smaller;
    font-style: italic;
    text-align: center;
  }
  
  /* Media Queries */
  @media (max-width: 600px) {
    .register-container {
      padding: 10px;
    }
  
    .form-group {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .form-group label {
      margin-bottom: 5px;
    }
  
    .register-container h2 {
      font-size: 1.5em;
    }
  
    .register-container button {
      padding: 6px;
      font-size: 0.8em;
    }
  
    .google-login button {
      padding: 6px;
      font-size: 0.8em;
    }
  }
  
.register-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; 
}


.register-spinner {
  border: 8px solid rgba(0, 0, 0, 0.1); 
  border-top: 8px solid #3498db; 
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: register-spin 1s linear infinite;
}

@keyframes register-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
