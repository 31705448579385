.profile-container {
  max-width: 500px;
  margin: 6px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
}

.profile-form {
  display: flex;
  flex-direction: column;
}

.profile-heading {font-family: "Playfair Display", serif;
  text-align: center;
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
  color: #ffb703;
  text-shadow: -1px -1px 0 #000,  
                1px -1px 0 #000,
               -1px  1px 0 #000,
                1px  1px 0 #000;
  }

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  cursor: text;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}

.edit-button, .logout-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.edit-button {
  background-color: #007bff;
}

.logout-button {
  background-color: #dc3545;
}

/* Media Queries */
@media (max-width: 768px) {
  .profile-container {
    padding: 15px;
  }

  .profile-form {
    flex-direction: column;
  }

  .form-buttons {
    flex-direction: column;
    width: 60%;
    margin: auto;
  }

  .edit-button, .logout-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .edit-button:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 480px) {
  .edit-button, .logout-button {
    font-size: 14px;
  }

  .form-group input {
    font-size: 14px;
    padding: 8px;
  }
}
