
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
  }

.noticeboard {
    background-color: white; /* Light mustard background */
    border: 4px solid #003366; /* Dark blue border */
    border-radius: 15px;
    padding: 10px;
    margin: 40px auto;
    width: 80%; /* Full width */
    box-shadow: 0 8px 16px rgba(128, 128, 128, 0.2); /* Grey box shadow */
    transition: transform 0.3s, box-shadow 0.3s;
}

.noticeboard:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(128, 128, 128, 0.3); /* Grey box shadow on hover */
}

.notice {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.glossy-heading {
    font-size: 36px;
    color: red; /* Dark blue text */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    /* margin-bottom: px; */
}

.announcement-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%; /* Full width */
}

.announcement-list .highlight {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 20px;
    color: #333;
    padding: 10px;
    background-color: #ffecb3;
    /* border-left: 5px solid #003366;  */
    border-radius: 5px;
}

.announcement-details {
    text-align: left;
    margin-bottom: 20px;
    width: 100%; /* Full width */
}

.enroll-button {
    background-color: #4CAF50; /* Green background */
    color: white;
    padding: 15px 25px;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 20px;
    display: inline-block;
    letter-spacing: 1px;
}

.enroll-button:hover {
    background-color: #388E3C; /* Darker green on hover */
    transform: scale(1.05);
}

@media only screen and (max-width: 600px) {
    .noticeboard {
        max-width: 90%;
        padding: 20px;
    }

    .glossy-heading {
        font-size: 26px;
    }

    .small-text {
        font-size: 13px;
    }

    .enroll-button {
        padding: 12px 20px;
        font-size: 18px;
    }
}