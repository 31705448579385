.contact-us-container {
  max-width: 500px;
  margin: auto;
  padding: 20px;
}
.contact-us-container h2{
  font-family: "Playfair Display", serif;
  text-align: center;
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
  color: #ffb703;
  text-shadow: -1px -1px 0 #000,  
                1px -1px 0 #000,
               -1px  1px 0 #000,
                1px  1px 0 #000;
  
}
.contact-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  
}

.form-group {
  margin-bottom: 20px;
}

.label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.input,
.textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

.textarea {
  resize: vertical;
  min-height: 120px;
}

.submit-button {
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #45a049;
}

.confirmation-message {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.confirmation-message {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.confirmation-message.success {
  background-color: #cce5ff;
  border-color: #b8daff;
  color: #004085;
}

.footer_contact_us{
color: black;
text-align: center;
display: flex;
flex-direction: column;
align-items: center; /* Align items horizontally center */
text-align: center; 
}
.call_icon{
height: 1px;
width: 1px;
}

svg path {
fill: black; /* Set fill color to white */
}

#email path {
fill: black; /* Set fill color to white */
}

#email{
margin-right: 5px;
}

.call-2{
display: flex;
}

.call_icon{
margin-right: 5px;
}
