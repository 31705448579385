/* Custom Styles - add before Tailwind */
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto';
}

code {
  font-family: 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

:global(.sidebar_sidebarContainer__uchJ1) {
  display: none !important;
}



/* Tailwind Styles - add after custom styles */
/* @tailwind base;
@tailwind components;
@tailwind utilities; */
